.DateRangeFilter {
  min-width: 182px;
  margin: 0 5px;
  top: -2px
}

.DateRangeFilter .ant-picker-input input {
  text-align: center !important;
}

.PrevNextButton {

}
