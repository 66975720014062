.activityList {
  padding: 0 5px 0 20px;
  display: flex;
}

.activity {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 6px 6px 5px 6px;
  position: relative;
  margin: 0 5px;
  height: 19px;
  gap: 5px;
  font-size: 16px;
}

.activityContent {
  display: flex;
  align-items: center; /* Vertically align name and timer */
  flex: 1; /* Ensure it takes available space */
  margin-right: 10px; /* Space before buttons */
}

.actionButtons {
  display: flex;
}

.activity.compact {
  width: 180px;
}

.ActivityTypesFilterLabelColor {
  min-width: 10px;
  height: 10px;
  border-radius: 5px;
}

.timer {
  /*width: 100px;*/
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.compact .timer {
  width: 40px;
  font-size: 12px;
  margin-left: 3px;
  height: 18px;
  min-width: 50px;
}

ul.ant-list-items > li.ant-list-item {
  padding: 5px 0 !important;
}

button.ant-btn span {
  font-weight: 600 !important;
}

.actionButton {
  margin: 0 0 0 10px;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: transparent;
  color: #6a5fc2
}

.compact .actionButton {
  margin: 0 0 -2px 3px;
}

.compact .actionButton svg {
  height: 16px;
}

.actionButton:hover {
  opacity: 0.5;
  transition: opasity .5s ease;
}

.actionButton span {
  font-size: 24px;
  margin-right: 10px;
}

.actionButton svg {
  height: 24px;
}

.editButton {
  opacity: 1;
  cursor: pointer;
}

.compact .title {
  font-size: 12px;
  width: 80px;
}

.title {
  display: flex;
  align-items: center;
  font-family: "Inter";
  text-overflow: ellipsis;
}

.title:hover {
  opacity: 0.5;
  transition: opacity .5s ease;
}

.restActivitiesContainer {
  position: absolute;
  bottom: -20px;
  left: 5px;
}

.more {
  cursor: pointer;
  font-size: 14px;
  margin-left: 14px;
  background: #f1f1f1;
  padding: 2px 7px;
  border-radius: 16px;
}

.activity {

  display: flex;
  align-items: center; /* Vertically align all items */
  justify-content: space-between; /* Space items across the container */
  padding: 14px 10px;
  width: 100%;
  box-sizing: border-box; /* Include padding in the width */
  /*border: 1px solid #ccc; !* Optional for visibility *!*/
}

.ActivityTypesFilterLabelColor {
  border-radius: 50%; /* Optional for circular label */
  margin-right: 10px; /* Space between label and content */
}

.activityContent {
  display: flex;
  align-items: center; /* Vertically align name and timer */
  flex: 1; /* Ensure it takes available space */
  margin-right: 10px; /* Space before buttons */
}

.title {
  cursor: pointer;
  flex-grow: 1; /* Push the timer to the right */
  margin-right: 10px; /* Space between name and timer */
}

.timer {
  /*width: 80px; !* Fixed width for the timer *!*/
  text-align: center; /* Center align text */
  /*font-size: 0.9rem; !* Adjust size if necessary *!*/
  white-space: nowrap; /* Prevent wrapping */
  /*font-family: "Roboto Mono", serif;*/
}

.actionButtons {
  display: flex;
  gap: 5px; /* Add spacing between buttons */
}

.actionButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}
