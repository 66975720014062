.select {
  width: 200px;
  margin-right: 5px;
}

.MenuIcon {
  margin: 0 5px;
  cursor: pointer;
}

.IntervalRow {
  margin-bottom: 10px;
  flex-wrap: nowrap;
}
