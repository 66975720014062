.page {
  display: flex;
  height: 100vh;
  position: relative;
}

.body {
  width: calc(100% - 64px);
  position: absolute;
  left: 64px;
}

.content {
  padding: 20px 10px;
}
