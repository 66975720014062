body {
  margin: 0;
  background: #fdf9f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span.ant-tag {
  background-color: #f0f0f0;
  margin-top: 3px;
  padding: 2px 6px !important;
  font-size: 11px;
  border-radius : 12px;
  font-weight: 500;
}

.ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #291437 !important;
  color: white !important;
}

.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}
