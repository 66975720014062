.ActivityPie {
  display: flex; justify-content: center;
}

.ActionRow {
  display: grid;
  grid-template-columns: 120px 55px 20px 20px;
  align-items: center;
}

.DaysIntervals {
  flex-grow: 1;
}

.InfoRow {
  align-items: center;
  gap: 10px;
}

.ActivityTypesFilterLabelColor {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.TypeLabel {
  align-items: center;
  color: rgb(115, 96, 123);
  font-size: 14px;
  font-weight: 600;
}

.DaysIntervalContent {
  flex-grow: 1;
}

.TableListRow {
  padding: 5px !important;
}
