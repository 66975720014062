.ActivityTypesFilter {
  width: 200px;
}

.ActivityTypesFilterLabelColor {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.ActivityTypesFilterLabel {
  display: flex;
  align-items: center;
}
