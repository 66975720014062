.RecordsPageActions {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ChartsContainer {
  margin-bottom: 30px;
}

.HistoryLabel {
   display: flex;
   align-items: center;
   gap: 10px;
}

.HistoryLabel span:nth-child(2) {
  font-size: 18px;
}

.HistoryLabel span:nth-child(2):hover {
  opacity: 0.6;
  cursor: pointer;
}

.HistoryActions {
  gap: 8px;
}

.PieContainer {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.PieContainer canvas {
  position: sticky;
  top: 140px;
}

.RecordsTitle {
  align-items: center;
  display: flex;
  gap: 8px;
}
