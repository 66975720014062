.CustomTreeSelect .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: inline-flex;
}

.CustomTreeSelect .ant-select-selection-overflow-item {
  display: none;
}

.CustomTreeSelect {
  position: relative;
  min-width: 120px;
}

.CustomTreeSelect .CustomTreeSelectIndicator {
  color: rgb(44, 19, 56);
  /*font-weight: 500;*/
  height: 32px;
  padding: 2px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4e8e8;;
  background: white;
  cursor: pointer;
  transition: border-color, opacity .2s ease;
}

.CustomTreeSelect .CustomTreeSelectIndicator:hover {
  border-color: rgba(217, 217, 217, 0.8) !important;
  opacity: 0.8;
}

.CustomTreeSelect .CustomTreeSelectIndicator.open {
  border-color: #d9d9d9 !important;
}

.CustomTreeSelect .ant-select {
  position: absolute;
  z-index: 10000;
  top: 36px;
}

.CustomTreeSelect .ant-select-selector {
  border-radius: 6px;
  box-shadow: none !important;
}

.CustomTreeSelectSeparator {
  position: absolute;
  top: 68px;
  width: 200px;
  height: 10px;
  background: white;
  z-index: 10000;
}

.CustomTreeSelectCount {
  border-radius: 4px;
  text-align: center;
  padding: 2px 4px;
  min-width: 15px;
  background: rgba(0, 21, 41, 0.6);
}


.CustomTreeSelectIndicator .CustomTreeSelectCount {
  background: #2d1b378c;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
}

div.ant-list-header {
  font-weight: 800;
  font-size: 16px;
  /*color: #281437;*/
}
