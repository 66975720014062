.AuthoredHeader {
  padding: 20px 10px;
  display: flex;
  background: white;
  border-bottom: solid #eae7eb;
  justify-content: space-between;
}

.HeaderActions {
  /*min-width: 325px;*/
}

.select {
  width: 160px;
  margin-right: 5px;
}

.ActivityTypesFilterLabelColor {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.ActivityTypesFilterLabel {
  display: flex;
  align-items: center;
}

.IntervalRow {
  margin-bottom: 10px;
  flex-wrap: nowrap;
}

.MenuIcon {
  margin: 0 5px;
  cursor: pointer;
}

.MenuIcon:hover {
  opacity: 0.5;
  transition: opacity .2s ease;
}
