.SideMenuContainer {
  position: fixed;
  max-width: 80px;
}

.SideMenu {
  height: 100vh;
}

.LogOut {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.LogOut svg {
  fill: white;
}
